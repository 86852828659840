import Router from 'next/router'
import { useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import CTAModal from '../components/CTAModal'
import media from '../styles/media'

const region = [
  {
    value: 'zhTW',
    name: '繁體中文(TW) $TWD',
  },
  {
    value: 'enUS',
    name: 'English(US) $USD',
  },
]

const footerBreakpoint = media.md

export default function FooterComponent() {
  const [locale, setLocale] = useState('zhTW')
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Footer>
      <CTAModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <ChangeRegionMobile onChange={(e) => setLocale(e.target.value)} icon={locale}>
        {region.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </ChangeRegionMobile>
      <Navigation>
        <span onClick={() => setModalOpen(true)}>開始訂製</span>
        {/* <Link href="/gallery">貼紙作品參考</Link> */}
        <a href="https://medium.com/stickerhd-%E8%B2%BC%E7%B4%99%E8%A3%BD%E4%BD%9C%E7%A7%98%E8%BE%9B" target="_blank" rel="noreferrer">
          貼紙設計與應用
        </a>
        <Link href="/faq">常見印刷問題</Link>
        {/* <a href="#">公版下載</a> */}
      </Navigation>
      <Information>
        <p>
          百變怪的營業時間：<br></br>週一至週五 10:00 am - 6:00pm
        </p>
        <p>
          <span>Email: </span>help@stickerhd.com<br></br>
          <span>電話: </span>+886 02-27736106<br></br>
          <span>地址: </span>台北市中正區臨沂街61巷18-2號1樓
        </p>
      </Information>
      <SocialLinks>
        <a href="https://lin.ee/YQEUkhz" target="_blank" rel="noreferrer">
          <img src="/images/Line.svg" />
        </a>
        <a href="https://www.instagram.com/stickerhd/" target="_blank" rel="noreferrer">
          <img src="/images/Instagram.svg" />
        </a>
        <a href="https://www.facebook.com/getstickerhd" target="_blank" rel="noreferrer">
          <img src="/images/Facebook.svg" />
        </a>
      </SocialLinks>
      <Placeholder />
      <Placeholder />
      <ChangeRegion onChange={(e) => setLocale(e.target.value)} icon={locale}>
        {region.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </ChangeRegion>
      <Copyright>
        <p>© {new Date().getFullYear()} StickerHD</p>
        <div>
          <a href="/document/privacy" target="_blank">
            隱私保護
          </a>
          <a href="/document/terms" target="_blank">
            使用者條款
          </a>
        </div>
      </Copyright>
    </Footer>
  )
}

const Footer = styled.div`
  background-color: var(--primary);
  padding: 40px 117px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 120px;
  row-gap: 24px;
  ${footerBreakpoint`
      grid-template-columns: 1fr;
      padding: 32px 24px;
      row-gap: 32px;
    `}
`
const Placeholder = styled.div`
  ${footerBreakpoint`
      display: none;
    `}
`
const Navigation = styled.div`
  a,
  span {
    cursor: pointer;
    display: block;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 12px;
  }
  ${footerBreakpoint`
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      a, span{
        margin-top: 0px;
      }
    `}
`
const Information = styled.div`
  p {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    margin-top: 12px;
    span {
      font-weight: 600;
    }
    ${footerBreakpoint`
        margin-top: 0px;
        margin-bottom: 0px;
        &:not(:first-of-type){
          margin-top: 12px;
        }
      `}
  }
`
const SocialLinks = styled.div`
  text-align: right;
  display: flex;
  justify-content: end;
  a {
    margin-left: 24px;
  }
  ${footerBreakpoint`
      justify-content: center;
      a:first-of-type{
        margin-left: 0px;
      }
      margin-top: 32px;
    `}
`
const ChangeRegion = styled.select`
  font-weight: 600;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  height: auto;
  padding: 0px;
  padding-left: 30px;
  background: url(/images/region/${(props) => props.icon}.svg);
  background-repeat: no-repeat;
  background-position: left center;
  display: none !important; /* remove this after i18n */
  ${footerBreakpoint`
      display: none;
    `}
`
const ChangeRegionMobile = styled(ChangeRegion)`
  display: none;
  ${footerBreakpoint`
      display: block;
    `}
`
const Copyright = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  a {
    margin-left: 16px;
  }
  ${footerBreakpoint`
      display: flex;
      flex-direction: column-reverse;
      p{
        margin-top: 24px;
      }
      a:first-of-type{
        margin-left: 0px;
      }
    `}
`
