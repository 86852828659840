import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { getEntryEndpoint, getEntriesEndpoint, getAssetsEndpoint, getResListByIds } from './contentfulUtils'
import simpleFetchAPI from '../utils/simpleFetchAPI'

const getCategoryWithProducts = async (slug) => {
  const url = getEntriesEndpoint({
    content_type: 'productCategory',
    'fields.slug': slug,
    select: 'sys.id,fields.name,fields.hidden,fields.metaTitle,fields.metaDescription,fields.slug,fields.products,fields.thumbnail',
  })
  const res = await simpleFetchAPI(url)

  if (res.items.length === 0) {
    throw new Error(`Category not found, slug is ${slug}`)
  }

  const category = res.items[0]
  const includes = res.includes

  const products = getFormattedProducts({ categoryProductMap: category.fields.products, includes })

  return {
    category: {
      id: category.sys.id,
      name: category.fields.name,
      isHidden: category.fields.hidden,
      metaTitle: category.fields.metaTitle,
      metaDescription: category.fields.metaDescription,
      slug: category.fields.slug,
      thumbnail: includes.Asset.find((asset) => asset.sys.id === category.fields.thumbnail.sys.id).fields.file.url,
    },
    products,
  }
}

const getCategories = async () => {
  const url = getEntriesEndpoint({ content_type: 'productCategory', order: '-sys.updatedAt' })
  const res = await simpleFetchAPI(url)

  const assetsEndpoint = getAssetsEndpoint()
  const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

  const tempCategories =
    res?.items?.map((category) => {
      return {
        id: category.sys.id,
        name: category.fields.name,
        isHidden: category.fields.hidden,
        thumbnail: assets.find((asset) => asset.sys.id === category.fields.thumbnail.sys.id).fields.file.url,
        description: category.fields.description,
        metaTitle: category.fields.metaTitle,
        metaDescription: category.fields.metaDescription,
        slug: category.fields.slug,
      }
    }) || []

  return tempCategories
}

const getProduct = async ({ slug, entryId }) => {
  try {
    if ((!slug && !entryId) || (slug && entryId)) {
      throw new Error('Either slug or entryId is required and could not be both provided.')
    }

    let product
    if (slug) {
      const productsUrl = `${getEntriesEndpoint({ content_type: 'product', 'fields.slug': slug })}`
      const products = await simpleFetchAPI(productsUrl)

      if (products.items.length === 0) {
        throw new Error(`Product not found, slug is ${slug}`)
      }

      product = products.items[0]
    } else if (entryId) {
      const productUrl = getEntryEndpoint(entryId)
      product = await simpleFetchAPI(productUrl)
    }

    let templateWidth = null
    let templateHeight = null
    // priceType：計價方式
    const priceTypeIds = product.fields.priceTypes.map((sysObj) => sysObj.sys.id)
    const tempPriceTypesFromRes = priceTypeIds.map(async (id) => {
      const priceTypeUrl = getEntryEndpoint(id)
      const priceType = await simpleFetchAPI(priceTypeUrl)

      return priceType
    })
    const priceTypesFromRes = await Promise.all(tempPriceTypesFromRes).then((values) => values)
    const priceTypes = priceTypesFromRes.map((e) => e.sys.contentType.sys.id)

    const priceOfFormat = priceTypesFromRes.find((e) => e.sys.contentType.sys.id === 'priceOfFormat')
    if (priceOfFormat) {
      templateWidth = priceOfFormat.fields.width
      templateHeight = priceOfFormat.fields.height
    }

    let sizesId
    if (priceTypes.includes('priceOfQuantity') || priceTypes.includes('priceOfSize')) {
      // TODO: 不 call
      // priceOfQuantity 直接顯示 可選數量列表
      // priceOfSize 拿 priceType 顯示
      // sizesId = product.fields.countSelectTable.sys.id
    } else {
      sizesId = product.fields.sizeSelectTable.sys.id
    }

    // const sizesId = product.fields.countSelectTable.sys.id
    // TODO: 除了數量計價以外都會有 sizeSelectTable，數量計價待討論怎麼處理
    // const sizesId = product.fields.countSelectTable.sys.id
    // const sizesId = product.fields.sizeSelectTable.sys.id
    const sizesUrl = getEntryEndpoint(sizesId)
    let sizesRes
    if (!priceTypes.includes('priceOfQuantity') && !priceTypes.includes('priceOfSize')) {
      sizesRes = await simpleFetchAPI(sizesUrl)
    }

    // if (priceTypes.includes('priceOfQuantity')) {
    // TODO: 應該是拿 countSelectTable 去 fetch
    // const sizesId = product.fields.countSelectTable.sys.id
    // }
    let sizes = []
    if (!priceTypes.includes('priceOfQuantity') && !priceTypes.includes('priceOfSize')) {
      sizes = Object.keys(sizesRes.fields.list).map((sizeKey) => ({
        name: sizeKey,
        width: sizesRes.fields.list[sizeKey][0],
        height: sizesRes.fields.list[sizeKey][1],
      }))
    }
    if (priceTypes.includes('priceOfSize')) {
      sizes = Object.keys(priceTypesFromRes[0].fields.data).map((sizeKey) => ({
        name: sizeKey,
        sizeAttributeName: sizeKey,
        width: priceTypesFromRes[0].fields.data[sizeKey].size[0],
        height: priceTypesFromRes[0].fields.data[sizeKey].size[1],
      }))
    }

    const productDescription = documentToHtmlString(product.fields.description)

    const assetsEndpoint = getAssetsEndpoint()
    const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

    const thumbnailUrl = assets.find((asset) => asset.sys.id === product.fields.thumbnail.sys.id).fields.file.url

    const imageUrls = product.fields.images
      .map((imageInfo) => assets.find((asset) => asset.sys.id === imageInfo.sys.id))
      .map((asset) => asset.fields.file.url)

    let additions = []
    if (product.fields.additions) {
      const additionsFromRes = await getResListByIds(product.fields.additions)
      const additionPromises = additionsFromRes
        .map((addition) => ({
          id: addition.sys.id,
          ...addition.fields,
        }))
        .map(async (additionField) => {
          const id = additionField.id
          const title = additionField.title
          const type = additionField.type
          const description = documentToHtmlString(additionField.description)
          const thumbnailUrl = assets.find((asset) => asset.sys.id === additionField.thumbnail?.sys?.id)?.fields?.file?.url || ''

          if (!additionField.options?.length) {
            return {}
          }

          const options = await getResListByIds(additionField.options)
          const optionsFields = options
            .map((option) => {
              const optionThumbnailUrl = assets.find((asset) => asset.sys.id === option.fields.thumbnail?.sys?.id)?.fields?.file?.url || ''
              if (option.fields.type !== type) {
                return {}
              }

              return {
                ...option.fields,
                id: option.sys.id,
                name: option.fields.title,
                thumbnail: optionThumbnailUrl,
              }
            })
            .filter((option) => Object.keys(option).length)

          return {
            id,
            title,
            type,
            description,
            thumbnail: thumbnailUrl,
            options: optionsFields,
          }
        })

      additions = await Promise.all(additionPromises).then((values) => values)

      additions = additions.filter((e) => Object.keys(e).length)
    }

    const hasSample = product.fields.sampleOption
    const canCustomSize = product.fields.canCustomSize
    const sizeDescription = documentToHtmlString(product.fields.sizeSelectInfo)

    let cuttingTypes = []
    if (product.fields.cuttingType) {
      const cuttingTypesFromRes = await getResListByIds(product.fields.cuttingType)
      cuttingTypes = cuttingTypesFromRes
        .map((cuttingType) => ({
          id: cuttingType.sys.id,
          ...cuttingType.fields,
        }))
        .map((cuttingType) => {
          const iconUrl = assets.find((asset) => asset.sys.id === cuttingType.icon?.sys?.id)?.fields?.file?.url || ''
          const thumbnailUrl = assets.find((asset) => asset.sys.id === cuttingType.thumbnail?.sys?.id)?.fields?.file?.url || ''
          const description = documentToHtmlString(cuttingType.description)

          return {
            ...cuttingType,
            icon: iconUrl,
            thumbnail: thumbnailUrl,
            description,
          }
        })
    }

    return {
      id: product.sys.id,
      title: product.fields.title,
      subtitle: product.fields.subTitle,
      sizes,
      description: productDescription,
      thumbnail: thumbnailUrl,
      images: imageUrls,
      tags: product.fields.tags || [],
      cuttingMethods: cuttingTypes,
      templateWidth,
      templateHeight,

      additions: additions,
      priceTypes,
      hasSample,
      canCustomSize,
      sizeDescription,
      isImageUploadRequired: product.fields.imageUploadRequired,

      metaTitle: product.fields.metaTitle || '',
      metaDescription: product.fields.metaDescription || '',
      slug: product.fields.slug || '',
    }
  } catch (err) {
    console.error('err is: ', err)
    return {}
  }
}

const getProducts = async ({ category }) => {
  const productUrl = getEntriesEndpoint({ content_type: 'product', links_to_entry: category, order: '-sys.createdAt' })
  const prodRes = await simpleFetchAPI(productUrl)

  return prodRes
}

const getProductsByFilter = async ({ categoryId, filter }) => {
  const url = getEntriesEndpoint({
    content_type: 'productCategory',
    'sys.id': categoryId,
    select: 'sys.id,fields.name,fields.hidden,fields.metaTitle,fields.metaDescription,fields.slug,fields.products',
  })
  const res = await simpleFetchAPI(url)

  if (res.items.length === 0) return []

  const category = res.items[0]
  const products = []

  // 將 filter.tags 和 filter.products 預處理成 Set 提高查找效率
  const filterTagIds = new Set(filter.tags?.map((tag) => tag.sys.id))
  const filterProductIds = new Set(filter.products?.map((product) => product.sys.id))

  // 建立一個 Map 來快速查找 res.includes.Entry 的項目
  const entryMap = new Map(res.includes.Entry.map((entry) => [entry.sys.id, entry]))

  // 使用 for...of 來取代 map 並提高效能
  for (const product of category.fields.products) {
    const target = entryMap.get(product.sys.id)
    if (!target) continue

    // 檢查 target 是否有與 filter.tags 匹配的 tag
    const hasMatchingTag = target.fields.tags.some((tag) => filterTagIds.has(tag.sys.id))
    if (!hasMatchingTag) continue

    // 如果 filter.products 存在，檢查 target 是否在 filter.products 中
    if (filter.products && !filterProductIds.has(target.sys.id)) continue

    products.push(target)
  }

  return getFormattedProducts({ categoryProductMap: products, includes: res.includes })
}

const getRecommendProducts = async ({ limit = 12 }) => {
  const rID = process.env.recommendId // 推薦項目 ID
  const productUrl = getEntriesEndpoint({ content_type: 'product', 'fields.tags.sys.id[in]': rID, order: '-sys.createdAt' })
  const prodRes = await simpleFetchAPI(productUrl)

  const assetsEndpoint = getAssetsEndpoint()
  const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

  const products =
    prodRes.items
      .map((item) => {
        return {
          id: item.sys.id,
          title: item.fields.title,
          slug: item.fields.slug,
          thumbnail: assets.find((asset) => asset.sys.id === item.fields.thumbnail.sys.id).fields.file.url,
        }
      })
      .sort(() => Math.random() - 0.5)
      .slice(0, limit) || []

  return products
}

const getGalleryItems = async () => {
  const url = getEntriesEndpoint({ content_type: 'galleryItems' })
  const res = await simpleFetchAPI(url)

  const assetsEndpoint = getAssetsEndpoint()
  const assets = await simpleFetchAPI(assetsEndpoint).then((res) => res.items)

  const tempItems =
    res?.items?.map((item) => {
      return {
        id: item.sys.id,
        thumbnail: assets.find((asset) => asset.sys.id === item.fields.thumbnail.sys.id).fields.file.url,
      }
    }) || []

  return tempItems
}

const getFormattedProducts = ({ categoryProductMap, includes }) => {
  const productEntries = categoryProductMap.map((p) => includes.Entry.find((e) => e.sys.id == p.sys.id))

  const result = productEntries.map((entry) => {
    const productDescription = documentToHtmlString(entry.fields.description)

    const asset = includes.Asset.find((asset) => asset.sys.id === entry.fields.thumbnail.sys.id)
    const thumbnailUrl = asset ? asset.fields.file.url : null
    const imageUrls = entry.fields.images
      .map((imageInfo) => includes.Asset.find((asset) => asset.sys.id === imageInfo.sys.id))
      .filter(Boolean) // 過濾掉如果沒有找到對應 asset 的狀況
      .map((asset) => asset.fields.file.url)

    return {
      id: entry.sys.id,
      slug: entry.fields.slug,
      title: entry.fields.title,
      subtitle: entry.fields.subTitle,
      description: productDescription,
      thumbnail: thumbnailUrl,
      images: imageUrls,
      tags: entry.fields.tags || null,
    }
  })

  return result
}

const getFilters = async ({ categoryId }) => {
  const url = getEntriesEndpoint({
    content_type: 'filter',
    links_to_entry: categoryId,
    select: 'sys.id,fields.title,fields.subTitle,fields.icon,fields.tags,fields.products',
  })
  const res = await simpleFetchAPI(url)

  const filterEntries = res.items
  if (filterEntries.length === 0) return []
  const { Asset } = res.includes

  const result = filterEntries.map((filterEntry) => {
    const iconAsset = Asset.find((asset) => asset.sys.id === filterEntry.fields.icon.sys.id)

    let filter = {
      id: filterEntry.sys.id,
      title: filterEntry.fields.title,
      subTitle: filterEntry.fields.subTitle,
      icon: iconAsset ? iconAsset.fields.file.url : null,
      tags: filterEntry.fields.tags,
    }

    if (filterEntry.products) {
      filter.products = filterEntry.products
    }

    return filter
  })

  return result
}

const getFaqSections = async () => {
  const sectionsUrl = getEntriesEndpoint({ content_type: 'faqCategory', order: 'sys.createdAt' })
  const sectionsRes = await simpleFetchAPI(sectionsUrl)

  if (!sectionsRes) return []

  const entryMap = Object.fromEntries(sectionsRes.includes.Entry.map((e) => [e.sys.id, e]))

  const data = sectionsRes.items.map((item) => ({
    section: item,
    questions: item.fields.questions.map((q) => entryMap[q.sys.id] || null),
  }))

  return data
}

const getFaqBySection = async ({ slug }) => {
  const url = getEntriesEndpoint({ content_type: 'faqCategory', 'fields.slug': slug })
  const res = await simpleFetchAPI(url)

  if (res.items.length === 0) {
    throw new Error(`FAQ Category not found, slug is ${slug}`)
  }

  const faqInfo = res.items[0]
  const { Entry } = res.includes
  const questions = faqInfo.fields.questions.map((q) => Entry.find((e) => e.sys.id == q.sys.id))

  return {
    questions: questions,
    section: faqInfo || null,
  }
}

const getRecommendedFaq = async () => {
  const faqUrl = getEntriesEndpoint({ content_type: 'faqCategory' })
  const faqRes = await simpleFetchAPI(faqUrl)

  const data = faqRes
    ? faqRes.includes.Entry.filter((e) => e.fields.isRecommended).map((q) => {
        const section = faqRes.items.find((i) => i.fields.questions.some((qs) => qs.sys.id == q.sys.id))
        return {
          section,
          ...q,
        }
      })
    : []

  return data.sort(() => Math.random() - 0.5).slice(0, 10)
}

const getFaq = async ({ sectionSlug, questionSlug }) => {
  const url = getEntriesEndpoint({ content_type: 'faqCategory', 'fields.slug': sectionSlug })
  const res = await simpleFetchAPI(url)

  if (res.items.length === 0) {
    throw new Error(`FAQ Category not found, slug is ${sectionSlug}`)
  }

  const section = res.items[0]
  const question = res.includes.Entry.find((q) => q.fields.slug === questionSlug)

  if (!question) {
    throw new Error(`FAQ not found, slug is ${questionSlug}`)
  }

  return {
    question,
    section: section || null,
    includes: res.includes,
  }
}

export {
  getCategoryWithProducts,
  getCategories,
  getProduct,
  getProducts,
  getFilters,
  getFaqSections,
  getFaqBySection,
  getRecommendedFaq,
  getFaq,
  getGalleryItems,
  getRecommendProducts,
  getProductsByFilter,
}
